var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { getManagementStatuses, getStatus } from '@backoffice/claim/status';
import ClaimTypeTile from '@backoffice/components/claim/ClaimTypeTile.vue';
import SynthesisCard from '@backoffice/components/claim/SynthesisCard.vue';
import { ARTISAN_APPOINTMENT_LABEL_TRAD_KEYS } from '@backoffice/appointment/labels';
import { breakInDamageTitle } from '@shared/breakInDamage/breakInDamageFields';
import { PERSONAL_PROPERTY_CATEGORY_XML_LABEL_TRAD_KEYS } from '@backoffice/constants/contractConstants';
import { contractSubscribedCapital, contractSubscribedOptionsLabels, } from '@backoffice/contract/contract';
import { CONTRACT_HOUSING_CAPACITIES_TRAD_KEYS, HOUSING_TYPES_TRAD_KEYS, } from '@shared/contract/labels';
import { EXIT_TYPES_CHOICES, EXIT_TYPES_TRAD_KEYS } from '@backoffice/claim/exit';
import { equipmentTitle } from '@shared/equipment/equipmentFields';
import { externalDamageTitle } from '@shared/externalDamage/externalDamageFields';
import { personalPropertyTitle } from '@shared/personalProperty/personalPropertyFields';
import { roomDamageSurfacesCountDescription, roomDamageTitle, } from '@shared/roomDamage/roomDamageFields';
import { ExitTypes, } from '@shared/types/api/claim';
import { electricalDamageTitle } from '@shared/electricalDamage/electricalDamageFields';
import { formatDate, formatOptionalDate, formatTime } from '@shared/utils/date';
import formatOptionalCentsAmount from '@shared/utils/formatOptionalCentsAmount';
import { uuidFromIri } from '@shared/utils/iri';
import { LayoutElementStatus } from '@shared/utils/layout';
import { EMPTY } from '@shared/utils/strings';
import { optionalTranslatableEnumTransform } from '@shared/utils/transform';
import { glassDamageTitle } from '@shared/glassDamage/glassDamageFields';
import { CLOSING_REASONS_LABELS } from '@backoffice/claim/claimClosingReasons';
import { translateChoices } from '@shared/choice';
import { InsurerFeatureFlag, insurerHasFeature } from '@shared/featureFlags/insurerFeatureFlags';
import { getClaimFiles } from '@backoffice/files/files';
import { CONFIG } from '@shared/environment/environment';
const claim = namespace('claim');
const settings = namespace('settings');
const REFUND_NOT_COMPUTABLE = 'Non chiffré';
let ClaimSummary = class ClaimSummary extends Vue {
    constructor() {
        super(...arguments);
        this.LayoutElementStatus = LayoutElementStatus;
    }
    get claimStatuses() {
        return [
            { value: null, label: this.$tc('claim.status.do_not_force') },
            ...translateChoices(getManagementStatuses(this.$config.insurerConfig), this.$i18n.tc.bind(this.$i18n)),
        ];
    }
    get exitTypes() {
        return EXIT_TYPES_CHOICES;
    }
    get id() {
        return this.$route.params.id;
    }
    get uuid() {
        return uuidFromIri(this.info.claim.id);
    }
    get forcedExit() {
        var _a, _b;
        return (_b = (_a = this.info) === null || _a === void 0 ? void 0 : _a.claim.forcedExit) !== null && _b !== void 0 ? _b : null;
    }
    set forcedExit(forcedExit) {
        this.updateDraftPayload({ forcedExit });
    }
    get forcedStatus() {
        var _a, _b;
        return (_b = (_a = this.info) === null || _a === void 0 ? void 0 : _a.claim.forcedStatus) !== null && _b !== void 0 ? _b : null;
    }
    set forcedStatus(forcedStatus) {
        this.updateDraftPayload({ forcedStatus });
    }
    get contractCardsData() {
        var _a, _b, _c, _d, _e, _f;
        const claim = (_a = this.info) === null || _a === void 0 ? void 0 : _a.claim;
        const contract = this.contract;
        if (!claim || !contract) {
            return [];
        }
        const translate = this.$tc.bind(this);
        const fullName = `${(_b = contract.contact) === null || _b === void 0 ? void 0 : _b.firstName} ${(_c = contract.contact) === null || _c === void 0 ? void 0 : _c.lastName}`;
        const email = (_d = contract.contact.email) !== null && _d !== void 0 ? _d : null;
        const phone = (_f = (_e = contract.contact.mobilePhone) !== null && _e !== void 0 ? _e : contract.contact.landlinePhone) !== null && _f !== void 0 ? _f : null;
        // Will be provided by backend when integrated with real customer database
        // For now we display value derived from UUID
        const refClient = uuidFromIri(contract.user).slice(0, 8).toUpperCase();
        const userCard = {
            title: fullName,
            nav: { tab: 'contrat' },
            info: [
                { icon: 'folder', label: 'Réf. client', value: refClient },
                {
                    icon: 'phone',
                    value: phone !== null && phone !== void 0 ? phone : EMPTY,
                    link: phone ? `tel:${phone}` : undefined,
                },
                {
                    icon: 'mail',
                    value: email !== null && email !== void 0 ? email : EMPTY,
                    link: email ? `mailto:${email}` : undefined,
                },
            ],
        };
        const contractCard = {
            title: 'Contrat',
            nav: { tab: 'contrat' },
            info: [
                {
                    label: 'Formule',
                    value: contract.formula,
                },
                {
                    label: 'Assureur',
                    value: claim.insurer.toUpperCase(),
                },
                ...contractSubscribedCapital(contract, translate),
                {
                    label: 'Options',
                    value: contractSubscribedOptionsLabels(contract, translate).join(', '),
                },
            ],
        };
        const riskCard = {
            title: 'Risque',
            nav: { tab: 'contrat' },
            info: [
                {
                    value: optionalTranslatableEnumTransform(contract.housingType.slug, HOUSING_TYPES_TRAD_KEYS, translate),
                },
                {
                    value: `${contract.specialConditions.address}${contract.specialConditions.addressAffix
                        ? ` ${contract.specialConditions.addressAffix}`
                        : ''} - ${contract.specialConditions.zipcode} ${contract.specialConditions.city}`,
                },
            ],
        };
        if (contract.specialConditions.rooms) {
            riskCard.info.push({
                value: `${contract.specialConditions.rooms} pièce${contract.specialConditions.rooms === 1 ? '' : 's'}`,
            });
        }
        riskCard.info.push({
            value: optionalTranslatableEnumTransform(contract.housingCapacity.code, CONTRACT_HOUSING_CAPACITIES_TRAD_KEYS, translate),
        });
        return [userCard, contractCard, riskCard];
    }
    get hasRealEstateDamages() {
        var _a;
        const counting = (_a = this.info) === null || _a === void 0 ? void 0 : _a.claim.counting;
        if (!counting) {
            return false;
        }
        return (counting.breakInDamages.length > 0 ||
            counting.externalDamages.length > 0 ||
            counting.equipments.length > 0 ||
            counting.roomDamages.length > 0 ||
            counting.glassDamages.length > 0 ||
            counting.electricalDamages.length > 0);
    }
    get userCommentDamagesInfo() {
        var _a, _b;
        const comment = (_b = (_a = this.info) === null || _a === void 0 ? void 0 : _a.claim.userComment) !== null && _b !== void 0 ? _b : null;
        if (!comment) {
            return [];
        }
        return [{ value: comment }];
    }
    get realEstateDamagesInfo() {
        var _a;
        const counting = (_a = this.info) === null || _a === void 0 ? void 0 : _a.claim.counting;
        if (!counting) {
            return [];
        }
        if (!this.hasRealEstateDamages) {
            return [{ value: 'Aucun' }];
        }
        const translate = this.$tc.bind(this);
        return [
            ...counting.breakInDamages.map((damage) => [damage, breakInDamageTitle(damage, translate)]),
            ...counting.externalDamages.map((damage) => {
                var _a, _b, _c;
                return [
                    damage,
                    externalDamageTitle(damage, (_c = (_b = (_a = this.info) === null || _a === void 0 ? void 0 : _a.claim.stormSnowHailDamageQualification) === null || _b === void 0 ? void 0 : _b.causeType) !== null && _c !== void 0 ? _c : null, translate),
                ];
            }),
            ...counting.equipments.map((damage) => [damage, equipmentTitle(damage, translate)]),
            ...counting.electricalDamages.map((damage) => [
                damage,
                electricalDamageTitle(damage, this.$i18n.tc.bind(this.$i18n)),
            ]),
            ...counting.roomDamages.map((damage) => {
                const surfaceDescription = roomDamageSurfacesCountDescription(damage);
                if (surfaceDescription) {
                    return [damage, `${roomDamageTitle(damage, translate)}, ${surfaceDescription}`];
                }
                return [damage, roomDamageTitle(damage, translate)];
            }),
            ...counting.glassDamages.map((damage) => [damage, glassDamageTitle(damage, translate)]),
        ].map(([damage, label]) => ({
            label,
            value: formatOptionalCentsAmount(typeof damage === 'object' && 'refund' in damage && 'valuation' in damage.refund
                ? damage.refund.valuation.replacement
                : null, REFUND_NOT_COMPUTABLE),
        }));
    }
    get hasPersonalPropertyDamages() {
        var _a;
        const counting = (_a = this.info) === null || _a === void 0 ? void 0 : _a.claim.counting;
        if (!counting) {
            return false;
        }
        return counting.personalProperties.length > 0;
    }
    get personalPropertyDamagesInfo() {
        var _a;
        const counting = (_a = this.info) === null || _a === void 0 ? void 0 : _a.claim.counting;
        if (!counting) {
            return [];
        }
        if (this.isPersonalPropertyLimitExceeded) {
            return [
                {
                    value: `+${this.personalPropertyLimit} biens sélectionnés`,
                },
            ];
        }
        if (!this.hasPersonalPropertyDamages) {
            return [{ value: 'Aucun' }];
        }
        return counting.personalProperties.map((personalProperty) => {
            var _a, _b, _c;
            const contractCategoryXml = (_a = personalProperty.category) === null || _a === void 0 ? void 0 : _a.insuranceContractXmlLabel;
            let categoryLabel = (_b = personalProperty.category) === null || _b === void 0 ? void 0 : _b.label;
            if (!categoryLabel && contractCategoryXml) {
                if (contractCategoryXml in PERSONAL_PROPERTY_CATEGORY_XML_LABEL_TRAD_KEYS) {
                    const tradKey = PERSONAL_PROPERTY_CATEGORY_XML_LABEL_TRAD_KEYS[contractCategoryXml];
                    if (this.$te(tradKey))
                        categoryLabel = this.$tc(tradKey);
                    else
                        categoryLabel = contractCategoryXml;
                }
                else
                    categoryLabel = contractCategoryXml;
            }
            const itemLabel = personalPropertyTitle(personalProperty).toLowerCase();
            const label = categoryLabel ? `${categoryLabel}, ${itemLabel}` : itemLabel;
            return {
                label,
                value: formatOptionalCentsAmount((_c = personalProperty.refund) === null || _c === void 0 ? void 0 : _c.valuation.replacement, REFUND_NOT_COMPUTABLE),
            };
        });
    }
    get declarationCardsData() {
        var _a;
        const claim = (_a = this.info) === null || _a === void 0 ? void 0 : _a.claim;
        const contract = this.contract;
        if (!claim || !contract) {
            return [];
        }
        const data = [
            {
                title: 'Déclaration',
                nav: { tab: 'declaration' },
                info: [
                    { label: 'Date de déclaration', value: formatOptionalDate(claim.createdAt) },
                    { label: 'Date du sinistre', value: formatOptionalDate(claim.damageDate) },
                    {
                        label: 'Statut',
                        value: claim.forcedStatus && claim.originalStatus
                            ? {
                                original: this.$tc(getStatus(this.$config.insurerConfig, claim.originalStatus).tradKey),
                                forced: this.$tc(getStatus(this.$config.insurerConfig, claim.forcedStatus).tradKey),
                            }
                            : this.$tc(getStatus(this.$config.insurerConfig, claim.status).tradKey),
                    },
                    ...(claim.closingReason !== null
                        ? [
                            {
                                label: 'Motif de clôture',
                                value: this.$tc(CLOSING_REASONS_LABELS[claim.closingReason]),
                            },
                        ]
                        : []),
                    {
                        label: 'Étape en cours',
                        value: claim.step ? claim.step.replace(/\//g, ' / ') : EMPTY,
                    },
                    {
                        label: 'Prochaine étape',
                        value: claim.nextStep ? claim.nextStep.replace(/\//g, ' / ') : EMPTY,
                    },
                ],
            },
        ];
        if (this.userCommentDamagesInfo.length) {
            data.push({
                title: 'Commentaire - assuré',
                info: this.userCommentDamagesInfo,
            });
        }
        data.push({
            title: 'Dommages immo.',
            nav: { tab: 'dommages' },
            info: this.realEstateDamagesInfo,
        });
        data.push({
            title: 'Dommages mobiliers',
            nav: { tab: 'dommages' },
            info: this.personalPropertyDamagesInfo,
        });
        return data;
    }
    get exitHasBeenForced() {
        var _a;
        return ((_a = this.info) === null || _a === void 0 ? void 0 : _a.claim.forcedExit) !== null;
    }
    get propositionDamagesInfo() {
        var _a;
        const claim = (_a = this.info) === null || _a === void 0 ? void 0 : _a.claim;
        if (!claim)
            return [];
        const t = (exitType = ExitTypes.Unknown) => {
            return this.$tc(EXIT_TYPES_TRAD_KEYS[exitType]);
        };
        const { forcedExit, originalExit, exit, exitExplanation } = claim;
        const claimExit = forcedExit && originalExit
            ? { original: t(originalExit), forced: t(forcedExit) }
            : t(exit !== null && exit !== void 0 ? exit : undefined);
        const info = [
            {
                label: 'Issue',
                value: claimExit,
            },
        ];
        if (exitExplanation || this.exitHasBeenForced) {
            info.push({
                label: 'Explication',
                value: exitExplanation !== null && exitExplanation !== void 0 ? exitExplanation : 'Cette issue a été forcée par un gestionnaire',
            });
        }
        return info;
    }
    getFormattedDate(startDateTime, endDateTime) {
        return `Le ${formatDate(startDateTime)}, de ${formatTime(startDateTime)} à ${formatTime(endDateTime)}`;
    }
    get appointmentCardsData() {
        var _a, _b, _c, _d, _e, _f, _g;
        const artisanAppointment = (_a = this.info) === null || _a === void 0 ? void 0 : _a.claim.artisanAppointment;
        const expertAppointment = (_b = this.info) === null || _b === void 0 ? void 0 : _b.claim.expertAppointment;
        const expert = (_c = this.info) === null || _c === void 0 ? void 0 : _c.claim.expert;
        const apiError = (_d = this.info) === null || _d === void 0 ? void 0 : _d.claim.noSuitableExpertTimeSlot;
        if (apiError && !!expert) {
            return [
                {
                    title: `Expert affecté`,
                    info: [
                        { label: 'Date', value: `À définir` },
                        { label: 'Société', value: expert.name },
                        { label: 'Type', value: (_f = (_e = this.info) === null || _e === void 0 ? void 0 : _e.claim.exit) !== null && _f !== void 0 ? _f : '' },
                    ],
                },
            ];
        }
        if (!expert || !(artisanAppointment || expertAppointment)) {
            return [];
        }
        const appointments = [];
        if (expertAppointment) {
            appointments.push({
                title: `Rendez-vous Expert`,
                info: [
                    {
                        label: 'Date',
                        value: this.getFormattedDate(expertAppointment.startDateTime, expertAppointment.endDateTime),
                    },
                    {
                        label: 'Société',
                        value: expertAppointment.expertSociety.name,
                        link: (_g = expertAppointment.expertSociety.extranetLink) !== null && _g !== void 0 ? _g : undefined,
                    },
                    { label: 'Type', value: expertAppointment.expertAppointmentMode },
                    { label: 'ID', value: uuidFromIri(expertAppointment.id) },
                ],
            });
        }
        if (artisanAppointment) {
            appointments.push({
                title: `Rendez-vous Artisan`,
                info: [
                    {
                        label: 'Date',
                        value: this.getFormattedDate(artisanAppointment.startDateTime, artisanAppointment.endDateTime),
                    },
                    { label: 'Société', value: artisanAppointment.artisanCompanyName },
                    {
                        label: 'Type',
                        value: this.$tc(ARTISAN_APPOINTMENT_LABEL_TRAD_KEYS[artisanAppointment.artisanAppointmentLabel]),
                    },
                ],
            });
        }
        return appointments;
    }
    get compensationCardsData() {
        var _a, _b, _c, _d;
        const data = [];
        data.push({
            title: 'Proposition',
            nav: { tab: 'indemnisation' },
            info: this.propositionDamagesInfo,
        });
        const hasDigitalReport = insurerHasFeature(InsurerFeatureFlag.BackofficeDigitalReport, (_b = (_a = this.initialClaim) === null || _a === void 0 ? void 0 : _a.type) === null || _b === void 0 ? void 0 : _b.trigram) && !!this.initialClaim;
        const hasJustificativeDocuments = insurerHasFeature(InsurerFeatureFlag.BackofficeJustificativeDocuments, (_d = (_c = this.initialClaim) === null || _c === void 0 ? void 0 : _c.type) === null || _d === void 0 ? void 0 : _d.trigram) &&
            !!this.initialClaim &&
            getClaimFiles(this.initialClaim).length > 0;
        if (hasDigitalReport || hasJustificativeDocuments) {
            const cardData = {
                title: 'Documents',
                info: [],
            };
            if (hasDigitalReport) {
                const pdfUrl = `${CONFIG.apiUrl}/pdf/claim-report/${uuidFromIri(this.initialClaim.id)}`;
                cardData.info.push({
                    label: 'Rapport digital',
                    value: 'Lien',
                    icon: 'file-chart',
                    link: pdfUrl,
                });
            }
            if (hasJustificativeDocuments) {
                const filesPdfUrl = `${CONFIG.apiUrl}/pdf/claim-files/${uuidFromIri(this.initialClaim.id)}`;
                cardData.info.push({
                    label: 'Documents justificatifs',
                    value: 'Lien',
                    icon: 'file-multiple',
                    link: filesPdfUrl,
                });
            }
            data.push(cardData);
        }
        data.push(...this.appointmentCardsData);
        return data;
    }
    get personalPropertyLimit() {
        return this.personalPropertyCountLimit;
    }
};
__decorate([
    claim.State('info')
], ClaimSummary.prototype, "info", void 0);
__decorate([
    claim.State('initialClaim')
], ClaimSummary.prototype, "initialClaim", void 0);
__decorate([
    claim.State('contract')
], ClaimSummary.prototype, "contract", void 0);
__decorate([
    claim.State('isEditingClaim')
], ClaimSummary.prototype, "isEditingClaim", void 0);
__decorate([
    claim.Getter('typeTrigram')
], ClaimSummary.prototype, "typeTrigram", void 0);
__decorate([
    claim.Getter('isPersonalPropertyLimitExceeded')
], ClaimSummary.prototype, "isPersonalPropertyLimitExceeded", void 0);
__decorate([
    settings.Getter('personalPropertyCountLimit')
], ClaimSummary.prototype, "personalPropertyCountLimit", void 0);
__decorate([
    claim.Action('updateDraftPayload')
], ClaimSummary.prototype, "updateDraftPayload", void 0);
ClaimSummary = __decorate([
    Component({
        middleware: 'hasAccess',
        components: { ClaimTypeTile, SynthesisCard },
        nuxtI18n: {
            paths: {
                en: '/claims/:id/synthesis',
            },
        },
    })
], ClaimSummary);
export default ClaimSummary;
