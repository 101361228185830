var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Prop } from 'vue-property-decorator';
import { CLAIM_TYPE_TRIGRAM_TRAD_KEYS, claimTypeInfoTradKey } from '@shared/claim/labels';
import { CLAIM_TYPE_IMAGES } from '@declaration/layout';
let ClaimTypeTile = class ClaimTypeTile extends Vue {
    get title() {
        return this.$tc(CLAIM_TYPE_TRIGRAM_TRAD_KEYS[this.trigram]);
    }
    get text() {
        return this.$tc(claimTypeInfoTradKey(this.trigram));
    }
    get image() {
        return CLAIM_TYPE_IMAGES[this.trigram];
    }
    get ariaLabel() {
        return `Type de sinistre: ${this.title}`;
    }
    get imageAlt() {
        return `Logo: ${this.title}`;
    }
    get typeClass() {
        const trigram = this.trigram.toLocaleLowerCase();
        return [
            `has-background-${trigram}`,
            `has-text-${trigram}-invert`,
            { 'claim-type-tile--small': this.small },
        ];
    }
};
__decorate([
    Prop({ type: String, required: true })
], ClaimTypeTile.prototype, "trigram", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], ClaimTypeTile.prototype, "description", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], ClaimTypeTile.prototype, "small", void 0);
ClaimTypeTile = __decorate([
    Component
], ClaimTypeTile);
export default ClaimTypeTile;
